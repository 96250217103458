import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { Space, Button, Layout, Menu, Avatar } from 'antd';
import { DropboxOutlined, CarOutlined, SettingFilled } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from '@store/hooks';
import { setCurrentMenuItem, userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { Permission, hasPermission } from '@enums/permission';

import {
    WarehouseIcon,
    AcceptanceIcon,
    LogisticsIcon,
    ImportTruckIcon,
    DeliveryTruckIcon,
    CartIcon,
    BoxesIcon,
    WorldTruckIcon,
    CalculatorIcon,
    MiniLogoIcon,
    MainLogoIcon,
} from '@icons/index';

import '@src/core/index.css';

const getDefaultOpenKey = (menuItem: string) => {
    switch (menuItem) {
        case 'import':
        case 'transit':
        case 'delivery':
            return 'logistics';

        case 'acceptance':
        case 'remainings':
        case 'shipmentjournals':
        case 'discrepancies':
            return 'warehouse';

        case 'consignees':
        case 'users':
            return 'settings';

        default:
            return '';
    }
};

const MainLayout = () => {
    const warehouseCode = process.env.REACT_APP_WHS_CODE;
    const warehouseName = process.env.REACT_APP_WHS_NAME;

    const { Header, Content, Sider } = Layout;

    const location = useLocation();

    const d = useAppDispatch();
    const currentMenuItem = useAppSelector<string>((s) => s.currentMenuItem);
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [defaultOpenKey, setDefaultOpenKey] = useState(getDefaultOpenKey(currentMenuItem));

    const [viewSetup] = useState<boolean>(hasPermission(userSession.permissions, Permission.ViewSetup));

    const [siderCollapsed, setSiderCollapsed] = useState<boolean>(false);

    function getItem(label: any, key: string, urlTo?: string, icon?: any, children?: Array<any>) {
        let itemlabel = urlTo ? <Link to={urlTo}>{label}</Link> : label;

        return {
            key,
            icon,
            children,
            label: itemlabel,
        };
    }

    useEffect(() => {
        let items = location.pathname.split('/');

        let key;
        switch (items[1]) {
            case 'logistics':
            case 'settings':
            case 'warehouse':
                key = items[2];
                break;
            default:
                key = items[1];
        }

        d(setCurrentMenuItem(key));
    }, []);

    const onLogout = () => {
        serverFetch(`auth/logout`, { method: 'POST' }).then(() => {
            d(userLoaded(undefined));
        });
    };

    useEffect(() => {
        setDefaultOpenKey(getDefaultOpenKey(currentMenuItem));
    }, [currentMenuItem]);

    const items = [
        getItem('Логистика', 'logistics', undefined, <LogisticsIcon />, [
            getItem('Импорт', 'import', '/logistics/import', <WorldTruckIcon />),
            getItem('Транзит', 'transit', '/logistics/transit', <ImportTruckIcon />),
            getItem('Доставка', 'delivery', '/logistics/delivery', <DeliveryTruckIcon />),
        ]),
        getItem('Склад', 'warehouse', undefined, <WarehouseIcon />, [
            getItem('Приемка', 'acceptance', '/warehouse/acceptance', <AcceptanceIcon />),
            getItem('Остатки', 'remainings', '/warehouse/remainings', <BoxesIcon />),
            getItem('Отгрузка', 'shipmentjournals', '/warehouse/shipmentjournals', <CartIcon />),
            getItem('Расхождения', 'discrepancies', '/warehouse/discrepancies', <CalculatorIcon style={{ fontSize: 18 }} />),
        ]),
    ];

    if (viewSetup) {
        items.push(
            getItem('Управление', 'settings', undefined, <SettingFilled />, [
                getItem('Грузополучатели', 'consignees', '/settings/consignees'),
                getItem('Пользователи', 'users', '/settings/users'),
            ])
        );
    }

    const onMenuClick = (e: any) => {
        d(setCurrentMenuItem(e.key));
    };

    const userMenuItems = [
        getItem(
            <Space style={{ cursor: 'pointer' }}>
                <Avatar
                    style={{ backgroundColor: '#fff' }}
                    size='large'
                    src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                />
                <div className='user'>{userSession.fullName}</div>
            </Space>,
            'account',
            undefined,
            undefined,
            [getItem('Личный кабинет', 'useraccount', '/useraccount', undefined)]
        ),
    ];

    const getLogo = () => {
        return siderCollapsed ? (
            <div className='logo'>
                <div className='sub-logo'>{warehouseCode}</div>
                <MiniLogoIcon style={{ fontSize: 30, display: 'table-cell', verticalAlign: 'middle' }} />
            </div>
        ) : (
            <div className='logo'>
                <div className='sub-logo'>{warehouseName}</div>
                <MainLogoIcon style={{ fontSize: 46, display: 'table-cell', verticalAlign: 'middle' }} />
            </div>
        );
    };

    return (
        <Layout style={{ height: '100vh', minHeight: '100vh' }}>
            <Sider
                collapsible={true}
                onCollapse={(e) => setSiderCollapsed(e)}
                width={250}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                }}
            >
                {getLogo()}
                <Menu
                    defaultOpenKeys={[defaultOpenKey]}
                    defaultSelectedKeys={[currentMenuItem]}
                    mode='inline'
                    items={items}
                    onClick={onMenuClick}
                />
            </Sider>
            <Layout>
                <Header className='main-header'>
                    <Space style={{ float: 'right' }}>
                        <Menu items={userMenuItems} mode='horizontal' style={{ width: 500, backgroundColor: '#ffffff' }} />
                        <Button type='text' className='logout' onClick={() => onLogout()}>
                            Выход
                        </Button>
                    </Space>
                </Header>
                <Content className='main-content'>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
