import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Table } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, MenuOutlined, PrinterOutlined } from '@ant-design/icons';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import PrintProcessing from '@controls/print-processing';

import RouteSheetPdf from '@print-forms/route-sheet/route-sheet-pdf';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { IRouteSheetItem } from '@entities/route-sheet-item';
import { IShipmentJournal } from '@entities/shipment-journal';

import { serverFetch } from '@src/core/server';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const RouteSheet = () => {
    const [api, contextHolder] = notification.useNotification();

    const { journalId } = useParams();

    const d = useAppDispatch();

    const [routeSheet, setRouteSheet] = useState<Array<IRouteSheetItem>>([]);
    const [journal, setJournal] = useState<IShipmentJournal>();

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [startPrint, setStartPrint] = useState<boolean>(false);
    const [printData, setPrintData] = useState<any>();

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        init();
    }, [refreshRequired]);

    useEffect(() => {
        if (printData) {
            updatePdf(<RouteSheetPdf printData={printData} />);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const init = () => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`warehouse/${journalId}/routesheet`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маршрутов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`shipmentjournals/${journalId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения документа отправки', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setRouteSheet(result[0][0]);
                setJournal(result[0][1]);

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Печать',
                        key: 'printRouteSheet',
                        disabled: loading || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            setPrintData({ journalNumber: journal?.journalNumber, routeSheet: routeSheet });
                        },
                    },
                ]}
            />
        );
    };

    const Row = ({ children, ...props }: RowProps) => {
        const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
            id: props['data-row-key'],
        });

        const style: React.CSSProperties = {
            ...props.style,
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
            transition,
            ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
        };

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if ((child as React.ReactElement).key === 'sort') {
                        return React.cloneElement(child as React.ReactElement, {
                            children: (
                                <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IRouteSheetItem> = [
            {
                key: 'sort',
                width: 40,
            },
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                align: 'center',
                width: 100,
            },
            {
                title: 'Город',
                dataIndex: 'cityName',
                width: 200,
            },
            {
                title: 'Адрес',
                dataIndex: 'address',
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        const onDragEnd = ({ active, over }: DragEndEvent) => {
            if (active.id !== over?.id) {
                setRouteSheet((previous) => {
                    const activeIndex = previous.findIndex((i) => i.index === active.id);
                    const overIndex = previous.findIndex((i) => i.index === over?.id);
                    return arrayMove(previous, activeIndex, overIndex);
                });
            }
        };

        return (
            <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                    // rowKey array
                    items={routeSheet.map((i) => i.index)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        rowKey='index'
                        size='small'
                        columns={columns}
                        dataSource={routeSheet}
                        loading={tableLoading}
                        pagination={false}
                        scroll={{ y: `calc(100vh - 250px)` }}
                    />{' '}
                </SortableContext>
            </DndContext>
        );
    };

    return (
        <>
            <FormHeader title={`Маршрутный лист "${journal?.journalNumber}"`} />
            {renderToolbar()}
            {renderTable()}

            {startPrint && <PrintProcessing />}

            {contextHolder}
        </>
    );
};

export default RouteSheet;
