import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Input, Select, Table, Button, Tooltip, Modal, Switch, Form, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    FilterFilled,
    MessageFilled,
    EyeOutlined,
    ExportOutlined,
    WarningOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import Filter from '@controls/filter/filter';
import FormHeader from '@controls/form-header/form-header';

import { delayAction } from '@extensions/utils';
import { exception, securityRestriction } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { notification } from 'antd';

import { IRemainingFilter } from '@entities/remaining-filter';
import { IRemaining } from '@entities/remaining';
import { IRemainingBoxGroup } from '@entities/remaining-box-group';
import { ICity } from '@entities/city';
import { Permission, hasPermission } from '@enums/permission';
import { useAppSelector } from '@store/hooks';
import { IUserSession } from '@entities/user-session';
import { ITruck } from '@entities/truck';

import '../warehouse.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IBoxGroupState {
    id?: string;
    comment?: string;
}

const filterContext: string = 'Remainings';

const Remainings = () => {
    const initFilter: IRemainingFilter = { cityIds: [], toShippingOnly: true };

    const now = dayjs();

    const { journalId } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IRemainingFilter>((s) => s.filters[filterContext]);

    const { TextArea } = Input;

    const d = useDispatch();

    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [headers, setHeaders] = useState<Array<IRemaining>>([]);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [openComment, setOpenComment] = useState<boolean>(false);
    const [boxGroupState, setBoxGroupState] = useState<IBoxGroupState>({});

    const [selectedCityRowKeys, setSelectedCityRowKeys] = useState<React.Key[]>([]);

    const [selectedBoxGroupRowKeys, setSelectedBoxGroupRowKeys] = useState<React.Key[]>([]);
    const [currentBoxGroup, setCurrentBoxGroup] = useState<IRemainingBoxGroup>();
    const [selectedMarkings, setSelectedMarkings] = useState<Array<string>>([]);
    const [selectedTruckIds, setSelectedTruckIds] = useState<Array<string | undefined>>([]);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('remainings/cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('remainings/trucks', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                setTrucks(result[0][1]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('remainings/groups', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения остатков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let entities = result[0][0];

                setHeaders(entities);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onGenerateShipmentJournal = () => {
        setLoading(true);

        serverFetch(`shipmentjournals/generate/${journalId}`, {
            method: 'POST',
            bodyData: { cityKeys: selectedCityRowKeys, markingCodes: selectedMarkings, truckIds: selectedTruckIds },
        })
            .then((data) => {
                setLoading(false);
                navigate(`/warehouse/shipmentjournals/${data}`);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка генерации документа отгрузки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSaveComment = () => {
        if (!boxGroupState) return;

        serverFetch(`warehouse/boxgroup/${boxGroupState.id}/comment`, { method: 'POST', bodyData: boxGroupState.comment })
            .then(() => {
                setBoxGroupState({});
                setRefreshRequired(true);
                setOpenComment(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения комментария', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Информация о партии',
                        key: 'edit-consignment',
                        disabled: !currentBoxGroup,
                        icon: <EyeOutlined />,
                        onClick: () => {
                            navigate(
                                `/warehouse/remainings/${currentBoxGroup?.markingId}/${currentBoxGroup?.countryId}/${currentBoxGroup?.truckId}`
                            );
                        },
                    },
                    {
                        label: 'Отгрузка',
                        key: 'shipment',
                        disabled: (selectedCityRowKeys.length < 1 && selectedBoxGroupRowKeys.length < 1) || loading,
                        icon: <ExportOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }
                            onGenerateShipmentJournal();
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Select
                        key='truck'
                        placeholder='Машина'
                        value={filter?.truckIds}
                        allowClear={true}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        style={{ width: 130 }}
                        showSearch
                        onChange={(value) => d(setFilter({ ...filter, truckIds: value }, filterContext))}
                        optionFilterProp='children'
                        options={trucks.map((t) => {
                            return { value: t.id, label: t.number };
                        })}
                    ></Select>,
                    <Select
                        key='city'
                        placeholder='Город'
                        value={filter?.cityIds}
                        allowClear={true}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        style={{ width: 200 }}
                        showSearch
                        onChange={(value) => d(setFilter({ ...filter, cityIds: value }, filterContext))}
                        optionFilterProp='children'
                        options={cities.map((c) => {
                            return { value: c.id, label: c.name };
                        })}
                    ></Select>,
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        key='markingCode'
                        style={{ width: 130 }}
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Form.Item key='toShippingOnly' label='На отгрузку' style={{ margin: 0 }}>
                        <Switch
                            checked={filter?.toShippingOnly}
                            onChange={(value) => {
                                d(setFilter({ ...filter, toShippingOnly: value }, filterContext));
                            }}
                        />
                    </Form.Item>,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectBoxGroupChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCityRowKeys([]);

        setSelectedBoxGroupRowKeys(selectedRowKeys);
        setSelectedMarkings([]);
        setSelectedTruckIds([]);

        let markings: Array<string> = [];
        let truckIds: Array<string | undefined> = [];

        if (selectedRowKeys.length === 1) {
            headers.map((h) => {
                h.remainingBoxGroups.map((b) => {
                    if (b.key === selectedRowKeys[0]) {
                        setCurrentBoxGroup(b);
                        markings.push(b.markingCode);
                        truckIds.push(b.truckId);
                    }
                });
            });
        } else {
            setCurrentBoxGroup(undefined);

            headers.map((h) => {
                h.remainingBoxGroups.map((b) => {
                    let key = selectedRowKeys.find((k) => k === b.key);
                    if (key) {
                        markings.push(b.markingCode);
                        truckIds.push(b.truckId);
                    }
                });
            });
        }

        setSelectedMarkings(markings);
        setSelectedTruckIds(truckIds);
    };

    const expandedRemainings = (record: IRemaining) => {
        const columns: ColumnsType<IRemainingBoxGroup> = [
            {
                title: 'ID',
                align: 'center',
                width: 80,
                onCell: (record: IRemainingBoxGroup) => ({
                    style: {
                        background: record.consigneeIsLocked ? '#ff9999' : '',
                    },
                }),
                render: (_, record) => {
                    return (
                        <Tooltip placement='top' title={record.consigneeLockReason} color='red'>
                            <div>{record.consigneeCode}</div>
                            <div className='transitWarehouseTag'>{record.warehouseCode}</div>
                        </Tooltip>
                    );
                },
            },
            {
                title: ' ',
                width: 40,
                render: (_, record: IRemainingBoxGroup) => {
                    let diffHours: number = 0;
                    if (record.acceptedOn) {
                        diffHours = now.diff(record.acceptedOn, 'hour');
                    }

                    return (
                        <>
                            {diffHours >= 24 ? (
                                <WarningOutlined style={{ color: 'red', fontSize: 18 }} />
                            ) : diffHours >= 10 ? (
                                <WarningOutlined style={{ color: 'orange', fontSize: 18 }} />
                            ) : (
                                <></>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 150,
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 150,
            },
            {
                title: 'AWB',
                width: 120,
                align: 'center',
                render: (_, record) => {
                    return (
                        record.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {record.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Отгрузка',
                width: 130,
                render: (_, record) => {
                    return (
                        record.loadingOn && (
                            <Tag color={record.isOverdue ? 'red' : 'var(--primary-color)'} style={{ marginLeft: 10, color: '#000000' }}>
                                {record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY')}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Машина',
                width: 100,
                render: (_, record) => {
                    return record.truckNumber || '- догруз -';
                },
            },
            {
                title: 'Дата отгрузки',
                width: 130,
                render: (_, record) => {
                    return record.departureOn && dayjs.utc(record.departureOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Дата приемки',
                render: (_: any, record: IRemainingBoxGroup) => {
                    return dayjs.utc(record.acceptedOn).local().format('DD.MM.YYYY HH:mm');
                },
                align: 'center',
                width: 130,
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'qty',
                align: 'center',
                width: 130,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: ' ',
                render: (_: any, record: IRemainingBoxGroup) => {
                    return (
                        <Tooltip placement='topRight' title={`${record.comment ? 'Изменить' : 'Добавить'} комментарий`}>
                            <Button
                                className={record.comment ? 'comment-btn' : 'no-comment-btn'}
                                type='text'
                                icon={<MessageFilled />}
                                onClick={() => {
                                    setBoxGroupState({ id: record.key, comment: record.comment });
                                    setOpenComment(true);
                                }}
                            />
                        </Tooltip>
                    );
                },
                width: 40,
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                width: 500,
            },
        ];
        return (
            <Table
                rowKey='key'
                size='small'
                columns={columns}
                dataSource={record.remainingBoxGroups}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedBoxGroupRowKeys,
                    onChange: onSelectBoxGroupChange,
                }}
            />
        );
    };

    const onSelectCityChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCityRowKeys(selectedRowKeys);
        setSelectedBoxGroupRowKeys([]);
        setSelectedMarkings([]);
        setSelectedTruckIds([]);
    };

    const renderTable = () => {
        const columns: ColumnsType<IRemaining> = [
            {
                title: 'Город',
                dataIndex: 'cityName',
                width: 990,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    let diffHours: number = 0;
                    if (record.acceptedOn) {
                        diffHours = now.diff(record.acceptedOn, 'hour');
                    }

                    return (
                        <>
                            <span>{record.cityName}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}

                            {diffHours >= 24 ? (
                                <WarningOutlined style={{ color: 'red', fontSize: 18, marginLeft: 5 }} />
                            ) : diffHours >= 10 ? (
                                <WarningOutlined style={{ color: 'orange', fontSize: 18, marginLeft: 5 }} />
                            ) : (
                                <></>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'totalQty',
                key: 'totalQty',
                width: 130,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{
                    expandedRowRender: expandedRemainings,
                    defaultExpandedRowKeys: headers && headers.map((h) => h.cityId),
                }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})`, x: 'max-content' }}
                rowSelection={{
                    selectedRowKeys: selectedCityRowKeys,
                    onChange: onSelectCityChange,
                }}
            />
        );
    };

    return (
        <>
            {journalId && <FormHeader title={`Добавить в документ`} />}
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            <Modal title='Комментарий' open={openComment} onOk={() => onSaveComment()} onCancel={() => setOpenComment(false)}>
                <TextArea
                    rows={4}
                    value={boxGroupState.comment}
                    onChange={(e) => setBoxGroupState({ ...boxGroupState, comment: e.target.value })}
                />
            </Modal>

            {contextHolder}
        </>
    );
};

export default Remainings;
