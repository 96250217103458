import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Input, Form, Space } from 'antd';
import { notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import { userLoaded } from '@store/actions';
import { exception } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

const UserAccount = () => {
    const navigate = useNavigate();

    const d = useDispatch();
    const userSession = useSelector((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState({});
    const [loading, setLoading] = useState(false);
    const [allowSetPassword, setAllowSetPassword] = useState(false);

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        setLoading(true);

        serverFetch(`users/account`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения учетной записи', ex, () => d(userLoaded(null)));
                setLoading(false);
            });
    };

    const onFinishSave = () => {
        setLoading(true);

        serverFetch(`users`, { method: 'PUT', bodyData: { user: entity } })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения учетной записи', ex, () => d(userLoaded(null)));
            });
    };

    return (
        <>
            <FormHeader title={`Личный кабинет`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onFinishSave}>
                    <Form.Item initialValue={entity.email} label='Email' name='email'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.fullName}
                        required
                        label='ФИО'
                        name='fullName'
                        rules={[{ required: true, message: 'Укажите ФИО' }]}
                    >
                        <Input
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, fullName: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item required label='Пароль' rules={[{ required: true, message: 'Укажите пароль' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input.Password
                                disabled={!allowSetPassword}
                                placeholder={!allowSetPassword ? '*******' : ''}
                                onChange={(data) => {
                                    setEntity({ ...entity, newPassword: data.target.value });
                                }}
                            />
                            <Button
                                type='primary'
                                onClick={() => {
                                    setAllowSetPassword(true);
                                }}
                            >
                                Сменить пароль
                            </Button>
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right', marginTop: 20 }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Сохранить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default UserAccount;
