import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Switch, Input, Form, Space, Divider, Select, Row, Col, notification } from 'antd';
import { MaskedInput } from 'antd-mask-input';

import FormHeader from '@controls/form-header/form-header';
import { getEnumList } from '@extensions/utils';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserPermission } from '@entities/user-permission';
import { IWarehouse } from '@entities/warehouse';

import { UserType, enumLabel } from '@enums/user-type';
import { Permission, hasPermission, enumLabel as permissionLabel } from '@enums/permission';
import { IEnumItem } from '@enums/enum-item';

import './user.css';

const User = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IUser>();

    const [loading, setLoading] = useState<boolean>(false);
    const [allowSetPassword, setAllowSetPassword] = useState<boolean>(!id);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageUsers));
    const [userTypes] = useState(getEnumList(UserType, enumLabel));
    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);

    const [loadedUserPermissions, setLoadedUserPermissions] = useState<Array<IUserPermission>>();
    const [userPermissions, setUserPermissions] = useState<Array<IUserPermission>>([]);
    const [permissions] = useState<Array<IEnumItem>>(getEnumList(Permission, permissionLabel));

    const [phone, setPhone] = useState<string>();

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`users/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                        })
                );

                promises.push(
                    await serverFetch(`users/${id}/permissions`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setWarehouses(result[0][0]);

                let user = result[0][1];
                if (user) {
                    setEntity(user);
                    setPhone(user.phone);
                } else {
                    setEntity({
                        id: undefined,
                        login: undefined,
                        email: undefined,
                        fullName: undefined,
                        isActive: undefined,
                        phone: undefined,
                        type: UserType.System,
                    });
                }

                let userPermissions = result[0][2];

                if (userPermissions) {
                    setLoadedUserPermissions(userPermissions);
                } else {
                    setLoadedUserPermissions([]);
                }

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (loadedUserPermissions) {
            let entities: Array<IUserPermission> = [];
            permissions.map((p) => {
                let permission = loadedUserPermissions.find((e) => e.permissionCode === p.value);
                if (permission) {
                    entities.push({ userId: id as string, name: p.label, permissionCode: p.value, isActive: true });
                } else {
                    entities.push({ userId: id as string, name: p.label, permissionCode: p.value, isActive: false });
                }
            });

            setUserPermissions(entities);
        }
    }, [loadedUserPermissions]);

    const onFinish = () => {
        if (!entity) return;

        setLoading(true);

        let activeUserPermissions = userPermissions.filter((p) => p.isActive);

        entity.phone = phone;

        let data = { user: entity, userPermissions: activeUserPermissions };

        serverFetch(`users`, { method: id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderPermissions = () => {
        return (
            <div className='permissions-container'>
                <Form.Item wrapperCol={{ span: 11 }}>
                    <Divider orientation='left'>Разрешения</Divider>
                </Form.Item>

                {userPermissions.map((p) => {
                    if (p.permissionCode < 0) {
                        return (
                            <Form.Item key={p.permissionCode} wrapperCol={{ offset: 1, span: 4 }}>
                                <Divider className='permissionGroup'>{p.name}</Divider>
                            </Form.Item>
                        );
                    }

                    return (
                        <Form.Item labelAlign='left' key={p.permissionCode} label={p.name} labelCol={{ offset: 1, span: 8 }}>
                            <Switch
                                disabled={isViewOnly}
                                defaultChecked={p.isActive}
                                onChange={(value) => {
                                    let entities = [...userPermissions];

                                    let entity = entities.find((e) => e.permissionCode === p.permissionCode);

                                    if (entity) {
                                        entity.isActive = value;

                                        setUserPermissions([...entities]);
                                    }
                                }}
                            />
                        </Form.Item>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <FormHeader title={`${id ? (isViewOnly ? 'Информация о пользователе' : 'Изменить пользователя') : 'Добавить пользователя'}`} />
            {entity && (
                <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onFinish}>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                initialValue={entity?.login}
                                required
                                validateFirst={true}
                                label='Логин'
                                name='login'
                                rules={[
                                    { required: true, message: 'Укажите логин' },
                                    {
                                        validator: async (_, value) => {
                                            let isEmailExist = await serverFetch(`users/loginexist/${id}/${value}`, { method: 'GET' })
                                                .then((data) => {
                                                    return data;
                                                })
                                                .catch((ex) => {
                                                    exception(api, 'Ошибка проверки логина', ex, () => d(userLoaded(undefined)));
                                                });

                                            if (!id && isEmailExist)
                                                return Promise.reject('Учетная запись с указанным логином уже существует');
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus
                                    disabled={isViewOnly || !!id}
                                    autoComplete='none'
                                    onChange={(data) => {
                                        setEntity({ ...entity, login: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item required label='Пароль' name='newPassword' rules={[{ required: !id, message: 'Укажите пароль' }]}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input.Password
                                        placeholder={id && !allowSetPassword ? '*******' : ''}
                                        disabled={!allowSetPassword || isViewOnly}
                                        onChange={(data) => {
                                            setEntity({ ...entity, newPassword: data.target.value });
                                        }}
                                    />
                                    <Button
                                        disabled={isViewOnly}
                                        type='primary'
                                        onClick={() => {
                                            setAllowSetPassword(true);
                                        }}
                                    >
                                        Сменить пароль
                                    </Button>
                                </Space.Compact>
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.type}
                                required
                                label='Тип'
                                name='type'
                                rules={[{ required: true, message: 'Укажите тип' }]}
                            >
                                <Select
                                    disabled={isViewOnly}
                                    onChange={(value) => {
                                        setEntity({ ...entity, type: value });
                                    }}
                                    options={userTypes.map((t) => {
                                        return { value: t.value, label: t.label };
                                    })}
                                />
                            </Form.Item>
                            {entity?.type == UserType.System && (
                                <Form.Item initialValue={entity.warehouseId} label='Склад' name='warehouse'>
                                    <Select
                                        allowClear
                                        disabled={isViewOnly}
                                        showSearch
                                        onChange={(value) => setEntity({ ...entity, warehouseId: value })}
                                        optionFilterProp='children'
                                        filterOption={(input, option) =>
                                            (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        filterSort={(a, b) =>
                                            (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                        }
                                        options={warehouses.map((w) => {
                                            return { value: w.id, label: `${w.code} (${w.cityName})` };
                                        })}
                                    />
                                </Form.Item>
                            )}
                            <Form.Item
                                initialValue={entity?.fullName}
                                required
                                label='ФИО'
                                name='fullName'
                                rules={[{ required: true, message: 'Укажите ФИО' }]}
                            >
                                <Input
                                    disabled={isViewOnly}
                                    onChange={(data) => {
                                        setEntity({ ...entity, fullName: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.phone}
                                required
                                name='phone'
                                label='Телефон'
                                rules={[{ required: true, message: 'Укажите телефон' }]}
                            >
                                <MaskedInput
                                    disabled={isViewOnly}
                                    size='middle'
                                    mask={'+7 (000) 000-00-00'}
                                    onChange={(data) => {
                                        setPhone(data.maskedValue);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.email}
                                validateFirst={true}
                                label='Email'
                                name='email'
                                rules={[
                                    { type: 'email', message: 'Неверный формат Email' },
                                    {
                                        validator: async (_, value) => {
                                            let isEmailExist = await serverFetch(`users/emailexist/${id}/${value}`, { method: 'GET' })
                                                .then((data) => {
                                                    return data;
                                                })
                                                .catch((ex) => {
                                                    exception(api, 'Ошибка проверки Email', ex, () => d(userLoaded(undefined)));
                                                });

                                            if (!id && isEmailExist)
                                                return Promise.reject('Учетная запись с указанным email уже существует');
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    disabled={isViewOnly}
                                    autoComplete='none'
                                    onChange={(data) => {
                                        setEntity({ ...entity, email: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label='Активно' name='isActive' valuePropName='checked'>
                                <Switch
                                    disabled={isViewOnly}
                                    defaultChecked={entity?.isActive}
                                    onChange={(value) => {
                                        setEntity({ ...entity, isActive: value });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Space size={'small'} style={{ float: 'right' }}>
                                    <Button type='text' onClick={() => navigate(-1)}>
                                        Отменить
                                    </Button>
                                    {!isViewOnly && (
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Сохранить
                                        </Button>
                                    )}
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={15} offset={1}>
                            {entity.type === UserType.System && renderPermissions()}
                        </Col>
                    </Row>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default User;
