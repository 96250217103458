export enum UserType {
    System = 1,
    MainClient = 2,
    Supplier = 3,
    Employee = 4,
    SubClient = 5,
}

export const enumLabel = (value?: UserType) => {
    switch (value) {
        case UserType.System:
            return 'Системный';
        case UserType.MainClient:
            return 'Основной клиент';
        case UserType.Supplier:
            return 'Поставщик';
        case UserType.Employee:
            return 'Сотрудник';
        case UserType.SubClient:
            return 'Субклиент';
        default:
            return '';
    }
};
