import React, { useState, useEffect } from 'react';

import { Table, Tag, Modal, Radio, Space, notification } from 'antd';
import { LoadingOutlined, ReloadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { ILostBox } from '@entities/lost-box';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

enum DiscrepancyFixingTypes {
    DirectDelivery = 1,
}

const InventoryDiscrepancies = () => {
    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [boxes, setBoxes] = useState<Array<ILostBox>>([]);
    const [loading, setLoading] = useState(false);
    const [refreshRequired, setRefreshRequired] = useState(true);

    const [selectedRowKeys, setSelectedBoxGroupRowKeys] = useState<React.Key[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [currentFixingType, setCurrentFixingType] = useState<DiscrepancyFixingTypes>(DiscrepancyFixingTypes.DirectDelivery);

    const [fixDiscrepancyLoading, setFixDiscrepancyLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('transitwarehouse/discrepancies', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения расхождений по складу', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setBoxes(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onFixDiscrepancy = () => {
        if (!currentFixingType || selectedRowKeys.length <= 0) return;

        setFixDiscrepancyLoading(true);

        let data = {
            boxIds: selectedRowKeys,
            fixingType: currentFixingType,
        };

        serverFetch(`transitwarehouse/discrepancies/fix`, { method: 'POST', bodyData: data })
            .then(() => {
                setFixDiscrepancyLoading(false);
                setRefreshRequired(true);
                setOpenModal(false);
            })
            .catch((ex) => {
                setFixDiscrepancyLoading(false);
                exception(api, 'Ошибка исправления расхождений', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Исправить',
                        key: 'fix',
                        disabled: loading || selectedRowKeys.length <= 0,
                        icon: <CheckCircleOutlined />,
                        onClick: () => {
                            setOpenModal(true);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedBoxGroupRowKeys(selectedRowKeys);
    };

    const renderTable = () => {
        const columns: ColumnsType<ILostBox> = [
            {
                title: 'Коробка,',
                dataIndex: 'number',
                width: 200,
                onCell: () => ({
                    style: {
                        fontWeight: 600,
                        color: 'red',
                    },
                }),
            },
            {
                title: 'AWB',
                width: 120,
                render: (_, record) => {
                    return (
                        record.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {record.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Дата счета',
                width: 130,
                render: (_, record) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 150,
            },
            {
                title: 'Машина,',
                dataIndex: 'truckNumber',
                width: 150,
            },
            {
                title: 'Дата отправки',
                width: 150,
                render: (_, record) => {
                    return dayjs.utc(record.shippedOn).local().format('DD.MM.YYYY');
                },
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={boxes}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 180px)`, x: 'max-content' }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {contextHolder}

            <Modal
                title='Исправить выбранные расхождения?'
                open={openModal}
                onOk={() => onFixDiscrepancy()}
                onCancel={() => setOpenModal(false)}
                confirmLoading={fixDiscrepancyLoading}
            >
                <Radio.Group
                    style={{ marginTop: 15 }}
                    onChange={(e) => {
                        setCurrentFixingType(e.target.value);
                    }}
                    value={currentFixingType}
                >
                    <Space direction='vertical'>
                        <Radio value={DiscrepancyFixingTypes.DirectDelivery}>Прямая доставка</Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        </>
    );
};

export default InventoryDiscrepancies;
