import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Divider, Col, Row, Input, Form, Space, Button, Modal, DatePicker, Tooltip, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import Trackpoints from './trackpoints';

import { exception } from '@extensions/notification';

import { getBoolean } from '@extensions/utils';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ITruck } from '@entities/truck';

import { TruckStatus, enumLabel } from '@enums/truck-status';
import { TruckType } from '@enums/truck-type';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Truck = () => {
    const { TextArea } = Input;

    const navigate = useNavigate();
    const [api, notificationContextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const { id } = useParams();

    const [entity, setEntity] = useState<ITruck>({
        id: undefined,
        number: undefined,
        departureOn: null,
        type: TruckType.Import,
        status: TruckStatus.New,
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        if (!id) return;

        setLoading(true);

        serverFetch(`trucks/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <Row>
                <FormHeader title={'Информация о машине'} />
            </Row>

            {!loading && (
                <>
                    <Row>
                        <Col span={12}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}>
                                <Form.Item initialValue={entity?.number} label='Номер машины' name='number' wrapperCol={{ span: 5 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                {id && (
                                    <Form.Item label='Статус' wrapperCol={{ span: 5 }}>
                                        <Input disabled={true} defaultValue={enumLabel(entity?.status)} />
                                    </Form.Item>
                                )}

                                {id && (
                                    <Form.Item
                                        label='Дата отправки'
                                        name='departureOn'
                                        initialValue={entity?.departureOn && dayjs.utc(entity.departureOn).local()}
                                    >
                                        <DatePicker disabled={true} format={'DD.MM.YYYY'} />
                                    </Form.Item>
                                )}
                                {entity?.trackingUrl && (
                                    <Form.Item initialValue={entity?.trackingUrl} label='Трекинг URL' name='trackingUrl'>
                                        <Tooltip placement='topRight' title='Показать на карте'>
                                            <a href={entity?.trackingUrl} target='_blank' rel='noreferrer'>
                                                {entity?.trackingUrl.slice(0, 52)}
                                            </a>
                                        </Tooltip>
                                    </Form.Item>
                                )}
                                <Form.Item label='Комментарий' name='comment' initialValue={entity?.comment}>
                                    <TextArea disabled={true} rows={4} />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 6, span: 10 }}>
                                    <Space size={'small'} style={{ float: 'right' }}>
                                        <Button type='text' onClick={() => navigate(-1)}>
                                            Закрыть
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                    {entity?.status !== TruckStatus.New && (
                        <>
                            <Divider orientation='left'>Трекинг</Divider>
                            <Row style={{ marginBottom: 15, marginTop: 15 }}>
                                <Col offset={1} span={15}>
                                    <Trackpoints truckId={id} viewOnly={true} />
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}

            {notificationContextHolder}
        </>
    );
};

export default Truck;
