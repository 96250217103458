import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Input, Form, Row, Col, Button, Space, Tooltip, Modal, notification } from 'antd';
import { RollbackOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { ITruck } from '@entities/truck';
import { IUserSession } from '@entities/user-session';

import { TruckStatus, enumLabel } from '@enums/truck-status';
import { Permission, hasPermission } from '@enums/permission';

//import '../warehouse.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Truck = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { id } = useParams();
    const navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();

    const { TextArea } = Input;

    const d = useAppDispatch();

    const [entity, setEntity] = useState<ITruck>();
    const [loading, setLoading] = useState<boolean>(false);
    const [modal, modalContextHolder] = Modal.useModal();

    useEffect(() => {
        setLoading(true);

        serverFetch(`transitWarehouse/trucks/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    const onRevertOnway = () => {
        serverFetch(`trucks/delivery/${id}/revertonway`, { method: 'POST' })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка изменения статуса', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <Row>
                <FormHeader title='Информация о машине' />
            </Row>

            {entity && (
                <>
                    <Row>
                        <Col span={12}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}>
                                <Form.Item initialValue={entity?.number} label='Номер машины' name='number' wrapperCol={{ span: 5 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item label='Статус' wrapperCol={{ span: 5 }}>
                                    <Space.Compact style={{ width: '100%' }}>
                                        <Input disabled={true} defaultValue={enumLabel(entity?.status)} />
                                        {hasPermission(userSession.permissions, Permission.FullAccess) &&
                                            entity.status === TruckStatus.Finished && (
                                                <Tooltip title='Вернуть статус "В Пути"'>
                                                    <Button
                                                        type='primary'
                                                        icon={<RollbackOutlined />}
                                                        onClick={() => {
                                                            modal.confirm({
                                                                title: `Изменить статус машины на "В Пути"?`,
                                                                okType: 'primary',
                                                                icon: <QuestionCircleOutlined />,
                                                                okText: 'ОК',
                                                                cancelText: 'Отмена',
                                                                onOk: () => {
                                                                    onRevertOnway();
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                    </Space.Compact>
                                </Form.Item>
                                <Form.Item initialValue={entity?.driverPhone} label='Телефон водителя' name='driverPhone'>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item label='Комментарий' name='comment' initialValue={entity?.comment}>
                                    <TextArea disabled={true} rows={4} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </>
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Truck;
